<template>
    <!-- Desktop navigation wrapper -->
    <div class="w-full">
        <!-- Navigation Row with dropdowns (contents get used on mobile, just displayed differently) -->
        <nav class="header-navigation"
            ref="containerElement"
            :class="{'is-active': navActive}"
            :style="{ height: containerHeight }">
            <ul class="header-items">
                <!-- Where the Navigation json options are used -->
                <li v-for="(item, index) in navigation" :key="item.title" :class="{
                    'xl:relative': !fullPopover(item),
                }">
                    <!-- Top-level item: node -->
                    <button v-if="item.children || item.html" class="header-link xl:hidden" @click="showPopover(index)">
                        <span class="header-linkLabel">{{ item.title }}</span>
                        <template v-if="item.children || item.html">
                            <div v-html="ChevronRight" class="w-2 h-auto"></div>
                        </template>
                    </button>

                    <a :href="item.url" class="header-link" :class="{'max-xl:hidden': item.children || item.html}">
                        <span class="header-linkLabel">{{ item.title }}</span>
                        <template v-if="item.children || item.html">
                            <div v-html="ChevronDown" class="w-auto h-2"></div>
                        </template>
                    </a>

                    <div v-if="item.children || item.html" class="header-popover" :class="{
                        'header-popover--full': fullPopover(item),
                        'is-active': activePopover === index
                    }">
                        <div class=" py-6 border-b-2 border-b-gray-9 xl:hidden">
                            <button class="header-link uppercase" @click="showPopover(index)">
                                <template v-if="item.children || item.html">
                                    <div v-html="ChevronLeft" class="w-2 h-auto"></div>
                                </template>

                                <span class="header-linkLabel">{{ item.title }}</span>
                            </button>
                        </div>

                        <!-- Children only (isMultiLevel == false) -->
                        <ul v-if="item.children" class="max-xl:py-6 max-xl:overflow-y-auto max-lg:overflow-x-hidden flex flex-col gap-6 xl:gap-2.5">
                            <!-- Mobile -->
                            <li class="xl:hidden">
                                <a :href="item.url" :class="['header-popoverLink', item.classnames]">
                                    <span class="header-linkLabel">{{ item.title }}</span>
                                </a>
                            </li>

                            <!-- Desktop -->
                            <li v-for="child in item.children" :key="child.title">
                                <template
                                    v-if="
                                        (
                                            child.classnames /* If the current item has classnames */
                                            && child.classnames.includes('primary') /* And the classnames include 'primary' */
                                            && (item.children[index + 1] /* And the next item exists */
                                            && !item.children[index + 1].classnames) /* And the next item doesn't have classnames */
                                        )
                                        || (
                                            item.children[index + 1]?.classnames /* Or the next item has classnames */
                                            && item.children[index + 1].classnames.includes('secondary') /* And the next item's classnames include 'secondary' */
                                        )
                                    ">

                                    <a class="mt-6" :href="child.url" :class="['header-popoverLink', child.classnames]">
                                        <span class="header-linkLabel">{{ child.title }}</span>
                                    </a>
                                    <hr class="mt-4 mb-3 border-t border-blue-light-1"/>
                                </template>

                                <a v-else :href="child.url" :class="['header-popoverLink', child.classnames]">
                                    <span class="header-linkLabel">{{ child.title }}</span>
                                </a>

                            </li>
                        </ul>

                        <!-- Nested (isMultiLevel == true) -->
                        <div class="max-xl:overflow-y-auto" v-if="item.html">
                            <!-- Children -->
                            <nav class="max-xl:py-6 max-xl:overflow-y-auto relative grid xl:grid-cols-3 gap-9 bg-white text-black">
                                <template v-for="column in item.html" :key="column">
                                    <a v-if="column.title && column.url" :href="column.url ? column.url : '#'" :class="column.classnames">
                                        {{ column.title }}
                                        <div v-if="column.classnames && column.classnames.includes('button')" v-html="ArrowRight" class="w-2"></div>
                                    </a>

                                    <!-- Subchildren -->
                                    <section v-else :class="column.classnames">
                                        <ul v-if="column.items">
                                            <li v-for="(item, index) in column.items" :key="item.title" :class="item.classnames ? item.classnames + '-parent' : ''">
                                                <a v-if="item.title" :href="item.url ? item.url : '#'" :class="item.classnames">
                                                    {{ item.title }}
                                                    <div v-if="item.classnames && item.classnames.includes('button')" v-html="ArrowRight" class="w-2"></div>
                                                    <hr class="mt-4 border-t border-blue-light-1"
                                                        v-if="
                                                            (
                                                                item.classnames /* If the current item has classnames */
                                                                && item.classnames.includes('primary') /* And the classnames include 'primary' */
                                                                && (column.items[index + 1] /* And the next item exists */
                                                                && !column.items[index + 1].classnames) /* And the next item doesn't have classnames */
                                                            )
                                                        "
                                                    />
                                                </a>
                                                <!-- Grandchildren: Bubble -->
                                                <ul v-if="item.items" class="bubble">
                                                    <li v-for="(subItem, index) in item.items" :key="subItem.title" :class="subItem.classnames ? subItem.classnames + '-parent' : ''">
                                                        <a :href="subItem.url ? subItem.url : '#'" :class="subItem.classnames">
                                                            {{ subItem.title }}
                                                        </a>

                                                        <hr class="mt-4 border-t border-blue-light-1"
                                                            v-if="
                                                                (
                                                                    subItem.classnames /* If the current item has classnames */
                                                                    && subItem.classnames.includes('primary') /* And the classnames include 'primary' */
                                                                    && (item.items[index + 1] /* And the next item exists */
                                                                    && !item.items[index + 1].classnames) /* And the next item doesn't have classnames */
                                                                )
                                                            "
                                                        />
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </section>
                                </template>
                            </nav>
                        </div>
                    </div>
                </li>

                <!-- Static Taxonomy button -->
                <!-- Note: Leaving this static until Taxonomy can get its own entry type or some other indicator than just the slug, which is what we're getting here anyway -->
                <li class="max-xl:order-[9998] xl:hidden">
                    <a href="/taxonomy" class="button button--uppercase button--blue-10 button--rounded button--full">
                        Taxonomy
                    </a>
                </li>

                <!-- Account section -->
                <li v-for="(item, key) in account" :key="key" :class="{
                    'xl:relative': !fullPopover(item),
                    'order-[9997]': userId,
                    'order-[9999]': !userId
                } ">
                        <!-- Top-level item: node -->
                    <button v-if="userId" class="header-link xl:hidden" @click="showPopover('account')">
                        <span class="header-linkLabel">My Account</span>
                        <template v-if="item.children || item.html">
                            <div v-html="ChevronRight" class="w-2 h-auto"></div>
                        </template>
                    </button>

                    <a :href="loginUrl" class="header-link" :class="{
                        'max-xl:hidden': userId,
                        'max-xl:button max-xl:button--rounded max-xl:button--full max-lg:button--uppercase': !userId
                    }">
                        <div v-html="User" class="w-auto h-5"></div>
                        <span class="header-linkLabel font-medium text-base">{{ userName }}</span>
                        <span v-if="!userId" class="xl:sr-only">Sign In / Register</span>
                        <template v-if="userId">
                            <div v-html="ChevronDown" class="w-auto h-2"></div>
                        </template>
                    </a>

                    <div v-if="userId" class="header-popover" :class="{
                        'header-popover--full': fullPopover(item),
                        'is-active': activePopover === 'account'
                    }">
                        <div class="py-6 border-b-2 border-b-gray-9 xl:hidden">
                            <button class="header-link uppercase" @click="showPopover('account')">
                                <template v-if="item.children || item.html">
                                    <div v-html="ChevronLeft" class="w-2 h-auto"></div>
                                </template>
                                <span class="header-linkLabel">My Account</span>
                            </button>
                        </div>

                        <!-- Children only (isMultiLevel == false) -->
                        <ul v-if="userId" class="max-xl:py-6 flex flex-col gap-6 xl:gap-2.5">
                            <li v-for="child in item.children" :key="child.title">
                                <a :href="child.url" :class="['header-popoverLink', child.classnames]">
                                    <span class="header-linkLabel">{{ child.title }}</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </nav>

        <div class="flex flex-row flex-nowrap gap-6 items-center">
            <search-bar :class="{'is-active': searchActive}"/>
            <a href="/taxonomy" class="max-xl:hidden button button--uppercase button--small button--blue-10 button--rounded">Taxonomy</a>
        </div>


    </div>

    <!-- Mobile navigation wrapper -->
    <div class="flex gap-4">
        <!-- Search button (gets content from desktop Search Bar markup) -->
        <button class="header-searchToggle button button--xsmall button--uppercase button--blue-default" :class="{'is-active': searchActive}" aria-label="Toggle Navigation" @click="toggleSearch">
            Search
            <div v-html="MagnifyGlass" class="w-[1.125rem] h-[1.125rem]"></div>
        </button>

        <!-- Hamburger menu (gets contents from desktop Navigation Row markup)  -->
        <button class="header-navToggle" :class="{'is-active': navActive}" aria-label="Toggle Navigation" @click="toggleNav">
            <span class="sr-only">Toggle Navigation</span>
            <i aria-hidden="true"></i>
            <i aria-hidden="true"></i>
            <i aria-hidden="true"></i>
        </button>
    </div>
</template>

<script setup>
    import { ref, onMounted, onUnmounted, computed } from 'vue';
    import axios from 'axios';
    import SearchBar from './SearchBar.vue';
    import ChevronDown from '/src/svgs/ChevronDown.svg?raw';
    import ChevronLeft from '/src/svgs/ChevronLeft.svg?raw';
    import ChevronRight from '/src/svgs/ChevronRight.svg?raw';
    import User from '/src/svgs/User.svg?raw';
    import ArrowRight from '/src/svgs/ArrowRight.svg?raw';
    import { store } from '../store.js';
    import MagnifyGlass from '/src/svgs/MagnifyGlass.svg?raw';


    import tippy from 'tippy.js';
    import 'tippy.js/dist/tippy.css';


    const navActive = ref(false);
    const searchActive = ref(false);
    const activePopover = ref(null);
    const csrftoken = ref({ name: '', value: ''});
    const favoriteEntriesRef = ref([]);
    let userId = computed(() => store.userId);
    let userName = computed(() => store.userName);
    let loginUrl = computed(() => store.loginUrl);
    const containerElement = ref(null);
    const containerHeight = ref('0px');

    const calculateHeight = () => {
        if (containerElement.value) {
            const rect = containerElement.value.getBoundingClientRect();
            const topOffset = rect.top;
            containerHeight.value = `${window.innerHeight - topOffset}px`;
        }
    };

    onMounted(async () => {
        calculateHeight();
        window.addEventListener('resize', calculateHeight);
        favoriteEntriesRef.value = await apiGetCurrentUserEntries('favorites');
        let favoriteIds = [];
        favoriteEntriesRef.value.forEach((item) => {
            favoriteIds.push(item.id);
        });
        store.updateFavorites(favoriteIds);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', calculateHeight);
    });

    const props = defineProps({
        navigation: {
            type: Array,
            required: true
        },
        account: {
            type: Array,
            required: true
        },
        selected: {
            type: [Array, Object],
            required: false
        },
    });

    const fullPopover = (item) => {
        return item.html ? true : false;
    };

    const toggleNav = () => {
        navActive.value = !navActive.value;
        if (navActive.value) {
            searchActive.value = false;
        }
        toggleBodyLock();
    };

    const toggleSearch = () => {
        searchActive.value = !searchActive.value;
        if (searchActive.value) {
            navActive.value = false;
        }
    };

    const showPopover = (index) => {
        if (activePopover.value === index) {
            activePopover.value = null;
        } else {
            activePopover.value = index;
        }
    };

    const toggleBodyLock = () => {
        if (navActive.value) {
            document.body.classList.add('is-locked');
        } else {
            document.body.classList.remove('is-locked');
        }
    };

    const renewCsrfToken = async () => {
        csrftoken.value = await fetch('/actions/blitz/csrf/token')
            .then(result => result.text())
            .then(result => {
                return {
                    name: 'CRAFT_CSRF_TOKEN',
                    value: result
                }
            });
    };

    const apiGetCurrentUserEntries = async (handle) => {
        await renewCsrfToken();

        const response = await axios.post('/actions/readinguniverse/user/' + handle, {
            [csrftoken.value.name]: csrftoken.value.value
        }).catch((error) => {
            console.error('Error fetching download entries:', error);
        });

        return response.data.items || [];
    };
</script>


<style scoped>
    /* Specific for the "Explore Teaching Topics" > "VIEW OUR GLOSSARY " button */
    /* This class is assigned within the "Menu" Craft CMS plugin */
    .button--navBottomLeft {
        @apply xl:absolute xl:bottom-0 xl:left-0;
    }
</style>
